<template>

    <!-- Includere il componente Menu con la prop isMenuOpen -->
    <infoMenu :isMenuOpen="infomenuopen" @closeMenu="closeinfomenu" />

  <div class="flex items-center justify-center space-x-4 w-full p-1">
    <div v-if="!error && daysUntil(datascadenza)<=10" class="inline-flex items-center rounded-full border border-azzurro-app clickable-shadow">
      <h2 class="text-md font-sans text-gray-600 m-1 whitespace-nowrap">La gallery scade fra: {{ daysUntil(datascadenza) }} giorni</h2>
      <button class="bg-azzurro-app border text-white py-1 px-2 rounded-full ml-2 " @click="openextensiontimepopup">
        Estendi
      </button>
    </div>
  </div>

  <div class="container pt-2">
    <div v-if="error" class="flex items-center justify-center">
      <div class="text-center">
        <div class="flex justify-center"> 
          <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 -960 960 960" width="60px" fill="#44a9f8">
            <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/>
          </svg>
        </div>
        <h2 class="text-md font-sans font-normal text-gray-600">{{ error }}</h2>
      </div>
    </div>
    <div v-else>
      <div class="ml-1">
      <div class="flex items-center">
        <div class="text-left w-full inline-flex items-center">
          <h2 class="text-xlp  text-gray-800 ml-2 font-semibold">{{ nome }}</h2>
        </div>
      </div>
      <p class="ml-2  text-sm text-gray-500">Created: {{ data_creazione }}</p>
    </div>
    <div class="flex items-center p-6 bg-white rounded-lg  space-x-6">
    
        <!-- Sinistra: Iniziali della Gallery -->
    <div class="flex-shrink-0">
      <div class="flex justify-center items-center w-16 h-16 text-white bg-azzurro-app  rounded-full text-2xl border-2 border-white outline outline-1 outline-azzurro-app ">
        {{ getInitials(nome) }}
      </div>
    </div>

    <!-- Centro: Numeri Snappy e Like -->
    <div class="flex-grow">
      <div class="flex items-center space-x-4 mt-4">
        <div class=" text-left">
          <span class="block text-xl font-bold text-gray-800">{{ snappy }}</span>
          <span class="text-xs text-gray-500">snappy</span>
        </div>
        <div class="text-left">
          <span class="block text-xl font-bold text-gray-800">{{ totallikes }}</span>
          <span class="text-xs text-gray-500">like</span>
        </div>
      </div>
    </div>

    <!-- Destra: Pulsante Share -->
    <div class="flex-shrink-0 flex">
      <button
        @click="sharePopupFun"
        class="bg-azzurro-app text-white py-1 px-4 rounded-lg flex items-center space-x-2 clickable-shadow text-sm"
      >
        <span>Share</span>
        <!-- Icona Share -->
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#ffffff" height="20px" width="20px" version="1.1" id="Layer_1" viewBox="0 0 458.624 458.624" xml:space="preserve">
        <path d="M339.588,314.529c-14.215,0-27.456,4.133-38.621,11.239l-112.682-78.67c1.809-6.315,2.798-12.976,2.798-19.871 c0-6.896-0.989-13.557-2.798-19.871l109.64-76.547c11.764,8.356,26.133,13.286,41.662,13.286c39.79,0,72.047-32.257,72.047-72.047 C411.634,32.258,379.378,0,339.588,0c-39.79,0-72.047,32.257-72.047,72.047c0,5.255,0.578,10.373,1.646,15.308l-112.424,78.491 c-10.974-6.759-23.892-10.666-37.727-10.666c-39.79,0-72.047,32.257-72.047,72.047s32.256,72.047,72.047,72.047 c13.834,0,26.753-3.907,37.727-10.666l113.292,79.097c-1.629,6.017-2.514,12.34-2.514,18.872c0,39.79,32.257,72.047,72.047,72.047 c39.79,0,72.047-32.257,72.047-72.047C411.635,346.787,379.378,314.529,339.588,314.529z"/>
      </svg>
      </button>
      
      <div v-if="!error" class="inline-flex items-center ">
      <button class="border-azzurro-app border text-white font-bold py-1 px-1 rounded-full mx-2 clickable-shadow" @click="adminandsettingspopupviewPopupFun">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#44a9f8">
          <path d="m405.38-120-14.46-115.69q-19.15-5.77-41.42-18.16-22.27-12.38-37.88-26.53L204.92-235l-74.61-130 92.23-69.54q-1.77-10.84-2.92-22.34-1.16-11.5-1.16-22.35 0-10.08 1.16-21.19 1.15-11.12 2.92-25.04L130.31-595l74.61-128.46 105.93 44.61q17.92-14.92 38.77-26.92 20.84-12 40.53-18.54L405.38-840h149.24l14.46 116.46q23 8.08 40.65 18.54 17.65 10.46 36.35 26.15l109-44.61L829.69-595l-95.31 71.85q3.31 12.38 3.7 22.73.38 10.34.38 20.42 0 9.31-.77 19.65-.77 10.35-3.54 25.04L827.92-365l-74.61 130-107.23-46.15q-18.7 15.69-37.62 26.92-18.92 11.23-39.38 17.77L554.62-120H405.38Zm73.54-260q41.85 0 70.93-29.08 29.07-29.07 29.07-70.92t-29.07-70.92Q520.77-580 478.92-580q-42.07 0-71.04 29.08-28.96 29.07-28.96 70.92t28.96 70.92Q436.85-380 478.92-380Z"/>
        </svg>        
      </button>
    </div>

    </div>
  </div>

  <!-- Descrizione della Gallery -->
  <div class=" bg-gray-100 p-4 mx-6 rounded-lg mb-4">
    <p class="text-gray-700 text-sm">{{ descrizione }}</p>
  </div>
    </div>

    <div class="mx-6">
      <setUsername class="z-10" v-if="setUsernamePopupview" @close="closesetUsernamePopup"/>
      <sharePopup class="z-10" v-if="sharepopupview " @close="closepopupshare"/>
      <photosView   v-if="!error && !load" ref="gallery" :key="imagesid.length" :passedimagesid="imagesid" @setselected="setselected" @setselectedimages="setselectedimages" @applyupload="refersh" @refresh="refersh"/>
      <settingsPopup class="z-10" v-if="adminandsettingspopupview" :title="nome" :description="descrizione" :galleryid="$route.params.id"  @close="adminandsettingspopupviewPopupFun" @closeandapply="closeandapplyadminandsettingspopupviewPopupFun" @changeusernamepopup="opensetUsernamePopup"/>
      <createOrder class="z-10" v-if="orderPopup" :galleryid="$route.params.id" :image_ids="selectedimagesids" @close="openOrderPopUp" @ordercomplete="closeOrderPopUpWithComplete"/>
      <extendGallery class="z-10" v-if="extensiontimepopup" :galleryId="$route.params.id" :currentExpiryDate="datascadenza" @close="openextensiontimepopup" @closeandapply="closeandapplyextensiontimepopup"/>
    </div>
    <div class="h-28"></div>
  </div>

  <!-- Bottoni in basso a sinistra con sfondo azzurro semi-trasparente -->
  <div v-if="selectedimages>0" class="fixed bottom-6 left-4 right-4 flex items-center space-x-2 p-2 bg-gray-200 border border-azzurro-app bg-opacity-95 mb-16 justify-between rounded-lg clickable-shadow">
    <span class="text-black font-medium">Foto selezionate {{selectedimages}}</span>
    <div class="flex px-2">
      <button @click="deleteimages" class="bg-red-500 text-white font-bold rounded-full p-2 mx-2 clickable-shadow">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff">
          <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm80-160h80v-360h-80v360Zm160 0h80v-360h-80v360Z"/>
        </svg>
      </button>
      <button @click="openOrderPopUp" class="flex items-center justify-center ">
        <img src="../assets/stampa.png" alt="Profile" class="h-10 w-10">
      </button>
    </div>
  </div>
</template>

<script>
import photosView from './photos.vue'
import sharePopup from './sharePopup.vue';
import settingsPopup from './adminAndSettings.vue';
import createOrder from './createOrder.vue';
import extendGallery from './extendGallery.vue';
import infoMenu from './infoMenu.vue'
import setUsername from './setUsername.vue'
export default {
  name: "homePage",
  components:{
    photosView,
    sharePopup,
    settingsPopup,
    createOrder,
    extendGallery,
    infoMenu,
    setUsername
  },
  data() {
    return {
      nome : "",
      data_creazione : "",
      descrizione: "", 
      datascadenza:'',
      sharepopupview: false,
      adminandsettingspopupview: false,
      extensiontimepopup:false,
      orderPopup:false,
      error: null,
      load : true,
      imagesid: [],
      selectedimages: 0,
      selectedimagesids: [],
      snappies:0,
      totallikes:0,
      infomenuopen:false,
      setUsernamePopupview: localStorage.getItem('username') != null? false : true,
    };
  },
  methods: {
    daysUntil(dateString) {
    const targetDate = new Date(dateString);
    const currentDate = new Date();

    // Calcolare la differenza in millisecondi
    const diffInMillis = targetDate - currentDate;

    // Convertire la differenza in giorni
    const diffInDays = Math.ceil(diffInMillis / (1000 * 60 * 60 * 24));

    return diffInDays;
    }, 
    getInitials(title) {
      const words = title.split(" ");
      if (words.length > 1) {
        return words[0][0].toUpperCase() + words[1][0].toUpperCase();
      } else {
        return title[0];
      }
    },
    refersh(){
      this.fetchGallery(this.$route.params.id);
    },
    adminandsettingspopupviewPopupFun(){
      this.adminandsettingspopupview = !this.adminandsettingspopupview
    },
    closeandapplyadminandsettingspopupviewPopupFun(){
      this.adminandsettingspopupview = false;
      this.fetchGallery(this.$route.params.id);
    },
    setselected(sel){
      this.selectedimages = sel
    },
    setselectedimages(selimg){
      this.selectedimagesids = selimg
    },
    setzeroselect(){
      console.log('zerooooo ho ricevuto ecezioneee')
      this.selectedimages = 0
      this.selectedimagesids = []
    },
    getclientid(){
      return localStorage.getItem('client_id_upload')?localStorage.getItem('client_id_upload'):0
    },
    closepopupshare() {
      this.sharepopupview = false;
    },
    changemenuselected(menuselected) {
      if(menuselected == 'openinfomenu'){
        this.infomenuopen = !this.infomenuopen;
        return;
      }
      this.$refs.gallery.changemenuselected(menuselected);
    },
    closeinfomenu(){
      this.infomenuopen = false
    },
    deleteimages() {
      this.$refs.gallery.deleteImages();
      this.zeroselected()
      this.fetchGallery(this.$route.params.id);
    },
    opensetUsernamePopup(){
      this.setUsernamePopupview = true;
      this.adminandsettingspopupview = false;
    },
    closesetUsernamePopup(){
      this.setUsernamePopupview = false;
    },
    sharePopupFun() {
      this.sharepopupview = !this.sharepopupview;
    },
    openOrderPopUp() {
      this.orderPopup = !this.orderPopup;
    },
    openextensiontimepopup() {
      this.extensiontimepopup = !this.extensiontimepopup;
    },
    closeandapplyextensiontimepopup(){
      this.extensiontimepopup = false;
      this.fetchGallery(this.$route.params.id);
    },
    closeOrderPopUpWithComplete() {
      this.orderPopup = !this.orderPopup;
      this.$refs.gallery.activateSelectForPrint();
    },
    zeroselected(){
      this.selectedimages = 0
      this.selectedimagesids = []
    },
    async fetchGallery(id) {
  try {
    const response = await fetch(`${this.$root.serverurl}/galleries/${id}/${await this.getclientid()}`);
    const data = await response.json();
    if (response.status === 200) {
      console.log(data);
      this.nome = data.nome;
      this.data_creazione = data.data_creazione.split('-').reverse().join('/').replace(/^(\d{4})\/(\d{2})\/(\d{2})$/, '$3/$2/$1');
      this.descrizione = data.descrizione;
      this.imagesid = data.images;
      this.imagesid.reverse() //dalla piú recente alla meno recente
      this.datascadenza = data.data_scadenza
      this.snappy	= data.snappy
      this.totallikes = parseInt(data.totallikes)
      this.load = false;
      if(this.$refs.gallery){
        this.$refs.gallery.internalrefreshimagesid(this.imagesid);
      }
    } else if (response.status === 403) {
      this.error = "Controlla la tua mail per attivare la galleria";
    } else {
      this.error = data.error || "Errore nel caricamento della galleria";
    }
  } catch (error) {
    console.log(error)
    this.error = "Errore nel caricamento della galleria";
  }
}

  },
  mounted() {
    this.fetchGallery(this.$route.params.id);
    if (this.$route.query.renew === 'true') {
      this.openextensiontimepopup();
    }
  }
};
</script>

<style scoped>
/* Aggiungi eventuali stili personalizzati qui */
.text-xlp {
    font-size: 1.4rem /* 24px */;
    line-height: 1.8rem /* 32px */;
}
</style>
