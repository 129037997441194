<template>
  <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
    <div class="w-5/6 max-w-md bg-white rounded-xl p-6 shadow-lg">
      <form @submit.prevent="createOrder" class="space-y-4">
        <h2 class="text-2xl font-semibold text-gray-800 mb-4">Crea Ordine</h2>
        <input type="email" v-model="email" placeholder="Email" class="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" required>
        <input type="text" v-model="nome" placeholder="Nome" class="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" required>
        <input type="tel" v-model="telefono" placeholder="Telefono" class="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" required>
        <input type="text" v-model="indirizzo_spedizione" placeholder="Indirizzo di Spedizione" class="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" required>
        <p class="text-lg font-medium text-gray-800">Totale ordine: <span class="text-blue-500">{{ costo_totale }}€</span></p>
        <div class="flex justify-between">
          <button type="submit" class="w-full bg-azzurro-app text-white font-semibold py-2 px-4 rounded-md transition duration-300 clickable-shadow">Crea Ordine</button>
          <button @click="close" type="button" class="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md transition duration-300 ml-2 clickable-shadow">Annulla</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "createOrderPopup",
  data() {
    return {
      email: "",
      nome: "",
      indirizzo_spedizione: "",
      costo_totale: 0 ,
      telefono: ""
    };
  },
  props: {
    image_ids: Array,
    galleryid: String
  },
  mounted() {
    this.costo_totale = (this.image_ids.length * 0.40 + 5).toFixed(2);
  },
  methods: {
    close(){
      this.$emit('close')
    },
    async createOrder() {
      console.log(this.image_ids)
      try {
        const response = await fetch(this.$root.serverurl+"/createOrder", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            id_gallery: this.galleryid,
            email: this.email,
            nome: this.nome,
            telefono: this.telefono,
            indirizzo_spedizione: this.indirizzo_spedizione,
            image_ids: this.image_ids,
            totale: this.costo_totale
          })
        });
        const responseData = await response.json();
        // Handle success response here, if needed
        alert(responseData.message);
        this.$emit('ordercomplete')
      } catch (error) {
        console.error("Error creating order:", error);
        // Handle error here, if needed
      }
    }
  }
};
</script>

<style scoped>
</style>
