<template>
  <div
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-95"
    @click.self="closeViewer"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
  >
    <button
      @click="closeViewer"
      class="absolute top-4 right-4 rounded-full z-10"
    >
      <svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="34px" fill="#FFFFFF">
        <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
      </svg>
    </button>

    <div
      class="relative w-full flex items-center justify-center transition-transform duration-300"
      :style="imageContainerStyle"
    >
      <img
        :src="currentCompressedImage"
        :key="currentImageKey"
        @load="onCompressedImageLoad"
        :data-full-src="currentImage"
        alt="Immagine a schermo intero"
        class="w-full object-cover transition-all duration-300"
        :style="imageStyle"
      />
    </div>

    <div class="absolute top-1/2 left-0 transform -translate-y-1/2 ml-2">
      <button @click="prevImage" class="bg-white px-1.5 rounded-full z-10">&lt;</button>
    </div>

    <div class="absolute top-1/2 right-0 transform -translate-y-1/2 mr-2">
      <button @click="nextImage" class="bg-white px-1.5 rounded-full z-10">&gt;</button>
    </div>

    <div class="absolute bottom-20 left-0 w-full flex justify-center">
      <div class="w-11/12 h-0.5 bg-gray-400"></div>
    </div>

    <div class="absolute bottom-4 left-4 flex items-center">
      <button @click="toggleLike" class="rounded-full p-2 flex items-center ">
        <div
          v-if="!has_liked"
          class="rounded-full flex items-center justify-center p-0.5 opacity-80"
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="38px" viewBox="0 -960 960 960" width="38px" fill="#e8eaed">
            <path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"/>
          </svg>
        </div>
        <div
          v-if="has_liked"
          class="rounded-full flex items-center justify-center p-0.5 opacity-80"
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="38px" viewBox="0 -960 960 960" width="38px" fill="#FF0000">
            <path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z"/>
          </svg>
        </div>
      </button>
      <span class="text-white flex items-center">{{ likes }}</span>
    </div>
    <p class="text-gray-300 text-xs absolute bottom-9 right-4">Caricato da: "{{ currentImageUsername() }}"</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      has_liked: 0,
      likes: 0,
      currentImageKey: "",
      isFullImageLoaded: false,
      imageStyle: {
        background: "transparent",
      },
      imageContainerStyle: {
        transform: "translateY(0)",
      },
      touchStartX: 0,
      touchStartY: 0,
      touchEndX: 0,
      touchEndY: 0,
      localCurrentIndex: this.currentIndex, // Variabile locale per currentIndex
    };
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currentImage() {
      return this.images[this.localCurrentIndex].url;
    },
    currentCompressedImage() {
      return `${this.currentImage}?compressed=true`;
    },
  },
  watch: {
    currentImage() {
      this.currentImageKey = `${this.currentImage}-${Date.now()}`;
      this.isFullImageLoaded = false;
      this.imageStyle.background = "black";
    },
    currentIndex(newValue) {
      this.localCurrentIndex = newValue;
    },
  },
  mounted() {
    this.updatelikedata();
  },
  methods: {
    currentImageUsername() {
      if(this.images[this.localCurrentIndex].upload_username === null){
        return "Anonimo"
      }
      return this.images[this.localCurrentIndex].upload_username;
    },
    updatelikedata() {
      console.log("like data updated ")
      this.has_liked = this.images[this.localCurrentIndex].has_liked;
      this.likes = this.images[this.localCurrentIndex].likes;
    },
    closeViewer() {
      this.$emit("close");
    },
    prevImage() {
      if (this.localCurrentIndex > 0) {
        this.localCurrentIndex--;
        this.updatelikedata();
        //this.$emit("updateCurrentIndex", this.localCurrentIndex); // Emetti l'evento per il padre
      }
    },
    nextImage() {
      if (this.localCurrentIndex < this.images.length - 1) {
        this.localCurrentIndex++;
        this.updatelikedata();
        //this.$emit("updateCurrentIndex", this.localCurrentIndex); // Emetti l'evento per il padre
      }
    },
    async toggleLike() {
      const clientId = this.getClientId();
      const imageId = this.images[this.localCurrentIndex].id;
      this.has_liked = !this.has_liked;
      this.likes += this.has_liked ? 1 : -1;
      try {
        await axios.post(`${this.$root.serverurl}/images/${imageId}/like`, {
          device_id: clientId,
        });
        this.$emit("refresh");
      } catch (error) {
        console.error("Error toggling like:", error);
      }
    },
    getClientId() {
      return localStorage.getItem("client_id_upload") || "0";
    },
    onCompressedImageLoad(event) {
      const imgElement = event.target;
      const fullSrc = imgElement.getAttribute("data-full-src");

      const fullImage = new Image();
      const currentKey = this.currentImageKey;

      fullImage.src = fullSrc;
      fullImage.onload = () => {
        if (this.currentImageKey === currentKey) {
          imgElement.src = fullSrc;
          this.isFullImageLoaded = true;
          this.imageStyle.background = "transparent";
        }
      };
    },
    onTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
      this.touchStartY = event.changedTouches[0].screenY;
    },
    onTouchMove(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.touchEndY = event.changedTouches[0].screenY;
    },
    onTouchEnd(event) {
      const swipeHorizontal = this.touchEndX - this.touchStartX;
      const swipeVertical = this.touchEndY - this.touchStartY;

      if (event.target.closest('button')) {
        return;
      }

      const minSwipeDistance = 50;

      if (Math.abs(swipeHorizontal) > Math.abs(swipeVertical) && Math.abs(swipeHorizontal) > minSwipeDistance) {
        if (swipeHorizontal < 0) {
          this.nextImage();
        } else if (swipeHorizontal > 0) {
          this.prevImage();
        }
      }

      if (Math.abs(swipeVertical) > minSwipeDistance) {
        if (swipeVertical > 0) {
          this.closeViewer();
        }
      }
    },
  },
};
</script>
