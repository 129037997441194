<template>
    <div class="flex items-center justify-center  ">
      <div class="p-6 max-w-sm w-full bg-white "> <!-- rounded-lg shadow-md -->
        <div v-if="loading" class="flex items-center justify-center">
          <svg class="animate-spin h-10 w-10 text-azzurro-app" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
          </svg>
          <span class="ml-2 text-gray-500">Attivazione della gallery...</span>
        </div>
        <div class="flex items-center justify-center" v-else>
          <div v-if="message" class="text-green-500 font-semibold">{{ message }}</div>
          <div v-if="error" class="text-red-500 font-semibold">{{ error }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        loading: true,
        message: '',
        error: ''
      };
    },
    mounted() {
      this.activateGallery();
    },
    methods: {
      async activateGallery() {
        const activationId = this.$route.params.activationid;
        try {
          const response = await axios.get(`${this.$root.serverurl}/activate/${activationId}`);
          this.message = response.data.message;
          this.$router.push({ name: 'viewgallery', params: { id: response.data.id } });
        } catch (error) {
          if (error.response && error.response.data) {
            this.error = error.response.data.error;
          } else {
            this.error = 'An unexpected error occurred';
          }
        } finally {
          this.loading = false;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any additional styling if needed */
  </style>
  