<template>
  <div>
    <!-- Menu Overlay -->
    <transition name="fade">
      <div v-if="isMenuOpen" class="fixed inset-0 bg-black bg-opacity-50 z-40" @click="closeMenu"></div>
    </transition>

    <!-- Menu Modal -->
    <transition name="slide-up">
      <div
        v-if="isMenuOpen"
        class="fixed bottom-0 left-0 right-0 bg-white rounded-t-lg shadow-lg z-50 p-6"
        @touchstart="startTouch"
        @touchmove="handleTouch"
        @touchend="endTouch"
      >
        <div class="flex justify-center items-center mb-4">
          <div class="h-1 w-12 bg-gray-400 rounded-full" @click="closeMenu()"></div>
        </div>
        <ul class="space-y-4 text-md">
          <li @click="newgallery">
            <a class="flex items-center">
              <svg class="inline-block w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000FF">
                <path d="M560-320h80v-80h80v-80h-80v-80h-80v80h-80v80h80v80ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z"/>
              </svg>
              Crea Nuova Gallery
            </a>
          </li>
          <li>
            <a class="flex items-center" rel="noopener noreferrer" href="https://www.oursnappy.com/print.html#print" target="_blank">
              <svg class="inline-block w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000FF"><path d="M160-560h640-640Zm80 440v-160H80v-240q0-51 35-85.5t85-34.5h560q51 0 85.5 34.5T880-520v30q-18-10-38-17t-42-10v-3q0-17-11.5-28.5T760-560H200q-17 0-28.5 11.5T160-520v160h80v-80h340q-16 17-29 37t-21 43H320v160h204q7 22 17 42t24 38H240Zm478-33L604-266l57-56 57 56 141-141 57 56-198 198Zm-78-487v-120H320v120h-80v-200h480v200h-80Z"/></svg>
              Info Stampa
            </a>
          </li>
          <li>
            <a class="flex items-center" rel="noopener noreferrer" href="https://www.instagram.com/oursnappy_com/" target="_blank">
              <svg class="inline-block w-5 h-5 mr-2" fill="#000000FF" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
              Seguici
            </a>
          </li>
          <li>
            <a class="flex items-center" rel="noopener noreferrer" href="https://www.oursnappy.com" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" class="inline-block w-5 h-5 mr-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000FF"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z"/></svg>
              oursnappy.com
            </a>
          </li>
          <li >
            <a class="flex items-center" href="mailto:info@ousnappy.com" target="_blank" rel="noopener noreferrer" >
              <svg xmlns="http://www.w3.org/2000/svg" class="inline-block w-5 h-5 mr-2"  height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000FF"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v58q0 59-40.5 100.5T740-280q-35 0-66-15t-52-43q-29 29-65.5 43.5T480-280q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480v58q0 26 17 44t43 18q26 0 43-18t17-44v-58q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h200v80H480Zm0-280q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"/></svg>
              Contatti
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    isMenuOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeMenu() {
      this.$emit('closeMenu'); // Comunica la chiusura al componente genitore
    },
    startTouch(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    handleTouch(event) {
      this.touchEndY = event.touches[0].clientY;
      if (this.touchEndY - this.touchStartY > 20) {
        event.preventDefault();
      }
    },
    newgallery(){
      this.closeMenu();
      this.$router.push({ name: 'newgallery' });
    },
    endTouch() {
      if (this.touchEndY - this.touchStartY > 50) {
        this.closeMenu();
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
/* Animazioni */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active, .slide-up-leave-active {
  transition: transform 0.3s ease-out;
}
.slide-up-enter, .slide-up-leave-to {
  transform: translateY(100%);
}
.slide-up-enter-to {
  transform: translateY(0);
}
.slide-up-leave {
  transform: translateY(0);
}

/* Fix per far partire l'animazione correttamente */
.slide-up-leave-active {
  transition: transform 0.3s ease-in;
}
</style>
