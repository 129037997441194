<template>
    <imageViewer v-if="imageshow" class="z-10" :images="images_url" :currentIndex="currentIndex" @close="closeViewer" @updateCurrentIndex="updateCurrentIndex" @refresh="refreshgallery"/>
    <uploadPhoto v-if="uploadPhotoSelected" class="z-10" @close="closeuploadphoto" @closeandapply="closeandapplyphotoupload"/>
    <div class="flex  flex-wrap">
        <div class="w-1/3" v-for="images in images_matrix" :key="images">
            <div class="relative m-1" v-for="image in images" :key="image.id">
                <div v-if="!loadedImages.includes(image.id)" class="bg-gray-300 h-32 w-full rounded-md animate-pulse"></div>
                <img v-show="loadedImages.includes(image.id)" :src="image.url+'?compressed=true'" :class="{'border-4 border-azzurro-app': isselectedimages(image.url), 'border-2 border-gray-400 opacity-80': selectImageForPrint && !isselectedimages(image.url)}" class="w-full rounded-md" @click="openImage(image.url)" @mousedown="startHold(image.url)" @mouseup="endHold" @mouseleave="cancelHold" @load="markAsLoaded(image.id)">
                <svg v-if="isselectedimages(image.url)" class="absolute top-2 right-2 rounded-full bg-azzurro-app" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" fill="#fff"/>
                </svg>
            <svg v-if="selectImageForPrint && !isselectedimages(image.url)" class="absolute top-2 right-2 rounded-full  " xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#9ca3af"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
            <svg v-if="image.can_delete === 1" class="absolute bottom-1 right-1 rounded-full bg-gray-800 p-0.5 opacity-40" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#e8eaed">
                <path d="M480-220q54 0 97.5-28.5T643-323q-16-14-32-29.5T579-385q-6 38-33.5 61.5T480-300q-42 0-71-29t-29-71q0-42 29.5-70.5T481-500q-12-18-23-36.5T437-574q-59 15-98 63t-39 111q0 75 52.5 127.5T480-220ZM360-800h50q-5 19-7.5 39t-2.5 41h-5l-73 80H160v480h640v-165q20-18 40-37.5t40-39.5v242q0 33-23.5 56.5T800-80H160q-33 0-56.5-23.5T80-160v-480q0-33 23.5-56.5T160-720h126l74-80Zm120 400Zm240 40q121-103 180.5-191T960-714q0-113-72.5-179.5T720-960q-95 0-167.5 66.5T480-714q0 75 59.5 163T720-360Zm0-107q-104-98-132-158t-28-89q0-81 50.5-123.5T720-880q59 0 109.5 42.5T880-714q0 29-28 89T720-467Zm-74-133 28-91-74-59h91l29-90 29 90h91l-74 59 28 91-74-56-74 56Zm74-74Z"/>
            </svg>
            <div v-if="!image.has_liked" class="absolute bottom-1 left-1 rounded-full flex items-center justify-center p-0.5 opacity-80">
                <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#ffffff"><path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z"/></svg>
                <p class="text-white ml-px">{{ image.likes }}</p>
            </div>
            <div v-if="image.has_liked"  class="absolute bottom-1 left-1 rounded-full flex items-center justify-center p-0.5 opacity-80">
                <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="19px" fill="#FF0000"><path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z"/></svg>                
                <p class="text-white ml-px">{{ image.likes }}</p>
            </div>
        </div>
    </div>
    </div>
</template>
  
  <script>
  import imageViewer from './viewPhoto.vue'
  import uploadPhoto from './uploadPhoto.vue';
  import axios from 'axios';
  export default {
    name: "photosView",
    emits: ['close', 'closeandapply', 'setselected', 'setselectedimages', 'applyupload', 'refresh'],
    components:{
        imageViewer,
        uploadPhoto,
    },
    data() {
      return {
        imageshow:false,
        selectImageForPrint:false,
        uploadPhotoSelected:false,
        currentIndex:0,
        images_matrix:[],
        images_url:[''],//inizializzo cosí per il render del componente imageshow
        selectedimages:[],
        imagesid:[],
        loadedImages: [], // array per tenere traccia delle immagini caricate
        loadingAll: true, // stato di caricamento per tutte le immagini
      };
    },
    mounted(){
        this.imagesid = this.passedimagesid
        this.calculateimageurl()
        this.images_matrix = this.splitArray()
        console.log(this.images_matrix)
        this.loadingAll = false; // Imposta su false dopo il montaggio
    },
    props:{
        passedimagesid : Object,
    },
    methods:{
        markAsLoaded(imageId) {
            // Aggiunge l'ID dell'immagine all'array quando è caricata
            if (!this.loadedImages.includes(imageId)) {
                this.loadedImages.push(imageId);
            }
        },
        internalrefreshimagesid(imageidnew){
            this.imagesid = imageidnew
            this.calculateimageurl()
            this.images_matrix = this.splitArray()
        },
        refreshgallery(){
            this.$emit('refresh')
        },
        setselected(){
            this.$emit('setselected',this.selectedimages.length)
        },
        closeandapplyphotoupload(){
            this.uploadPhotoSelected=false;
            this.$emit('applyupload');
        },
        setselectedimages(){
            this.$emit('setselectedimages', this.selectedimages.map(url => {
                const urlWithoutQuery = url.split('?')[0];
                const parts = urlWithoutQuery.split('/');
                return parts[parts.length - 1];
            }))
        },
        printphoto(){
            console.log(this.selectedimages)
        },
        deletephoto(){
            console.log(this.selectedimages)
        },
        calculateimageurl(){
            let a = []
            for(let i = 0; i < this.imagesid.length; i++){
                a.push({
                    url:`${this.$root.serverurl}/images/${this.imagesid[i].id}`,
                    id:this.imagesid[i].id,
                    can_delete: this.imagesid[i].can_delete,
                    likes:this.imagesid[i].likes,
                    has_liked:this.imagesid[i].has_liked,
                    upload_username:this.imagesid[i].upload_username
                })
            }
            this.images_url = a
        },
        closeuploadphoto(){
            this.uploadPhotoSelected=false;
        },
        changemenuselected(menuselected){
            if(menuselected=='uploadphoto'){
                this.uploadPhotoSelected =  !this.uploadPhotoSelected
            }else if(menuselected=='select'){
                this.selectImageForPrint =  !this.selectImageForPrint
            }
        },
        splitArray() {
            let newarr = [[], [], []]; // Crea tre sottoarray vuoti
            for (let i = 0; i < this.images_url.length; i++) {
                newarr[i % 3].push(this.images_url[i]); // Inserisce l'elemento nell'array corrispondente ciclicamente
            }
            return newarr;
        },
        openImage(image){
            if(this.selectImageForPrint){// se sono in selezione
                this.addOrRemoveImage(image)
                return
            }
            this.imageshow = true
            this.currentIndex = 0
            for (let index = 0; index < this.images_url.length; index++) {
                if(this.images_url[index].url === image){
                    this.currentIndex = index
                    break
                }
            } 
        },
        addOrRemoveImage(image){
            if(this.isselectedimages(image)){
                this.selectedimages.splice(this.selectedimages.indexOf(image), 1)  //se c'è rimuovo
                if(this.selectedimages.length==0){
                    this.selectImageForPrint=false
                }
            }else{
                this.selectedimages.push(image) //se non c'è aggiungo
            }
            this.setselected()
            this.setselectedimages()
        },
        isselectedimages(image){
            return this.selectedimages.indexOf(image) != -1
        },
        updateCurrentIndex(index){
            this.currentIndex = index
        },
        closeViewer(){
            this.imageshow = false
        },
        activateSelectForPrint(){
            //this.selectImageForPrint = true
            if (this.selectImageForPrint){
                this.selectedimages=[]
                this.setselected()
                this.setselectedimages()
            }
            this.selectImageForPrint = !this.selectImageForPrint
            
        },
        uploadPhoto(){
            this.uploadPhotoSelected = true
        }, 
        startHold(image) {
            let t = this
            // Avvia il timer quando il pulsante viene premuto
            this.holdTimer = setTimeout(() => {
                if(t.selectImageForPrint)return;// se é gia true non faccio nulla
                
                this.selectedimages=[]
                t.selectImageForPrint = true
                t.addOrRemoveImage(image)
            }, 1000);
        },
        endHold() {
            // Cancella il timer se il pulsante viene rilasciato prima dei 3 secondi
            clearTimeout(this.holdTimer);
        },
        cancelHold() {
            // Cancella il timer se il cursore esce dall'area del pulsante
            clearTimeout(this.holdTimer);
        },
        getclientid(){
            return localStorage.getItem('client_id_upload')?localStorage.getItem('client_id_upload'):0
        },
        deleteImages() {
            // Ottieni gli ID delle immagini da eliminare
            const imageIDs = this.selectedimages.map(url => {
                const urlWithoutQuery = url.split('?')[0];
                const parts = urlWithoutQuery.split('/');
                return parts[parts.length - 1];
            });

            let t = this

            axios.post(`${this.$root.serverurl}/deleteimages`, {
                image_ids: imageIDs,
                client_id: this.getclientid(),
                admincodehash: localStorage.getItem(`admincodehashforgalleryid:${this.$route.params.id}`)?localStorage.getItem(`admincodehashforgalleryid:${this.$route.params.id}`):null,
                galleryid: this.$route.params.id,
            })
            .then(response => {
                //console.log(response.data.message);
                alert(response.data.message);
                t.activateSelectForPrint();
                t.$emit('refresh')
            })
            .catch(error => {
                console.error('Errore durante l\'eliminazione delle immagini:', error);
                alert('Errore durante l\'eliminazione delle immagini:', error);
            });
        }
    }
  };
  </script>


<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 33.33333%;
  max-width: 33.33333%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}


</style>