<template>
  <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
    <div v-if="!showAdminPanel" class="bg-white rounded-xl p-6 shadow-lg w-11/12 max-w-lg mx-auto">
      <h2 class="mb-4 text-2xl font-bold text-center">Impostazioni</h2>
      <div class="flex flex-col space-y-4">
        <!-- Button to emit the change username popup event -->
        <button @click="$emit('changeusernamepopup')" class="flex items-center px-4 py-2 bg-azzurro-app text-white rounded-md  transition clickable-shadow">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff" stroke="currentColor"><path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/></svg>
          Modifica Username
        </button>

        <!-- Button to toggle the admin panel (login or manage) -->
        <button @click="toggleAdminPanel" class="flex items-center px-4 py-2 bg-azzurro-app text-white rounded-md transition clickable-shadow">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M680-280q25 0 42.5-17.5T740-340q0-25-17.5-42.5T680-400q-25 0-42.5 17.5T620-340q0 25 17.5 42.5T680-280Zm0 120q31 0 57-14.5t42-38.5q-22-13-47-20t-52-7q-27 0-52 7t-47 20q16 24 42 38.5t57 14.5ZM480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v227q-19-8-39-14.5t-41-9.5v-147l-240-90-240 90v188q0 47 12.5 94t35 89.5Q310-290 342-254t71 60q11 32 29 61t41 52q-1 0-1.5.5t-1.5.5Zm200 0q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80ZM480-494Z"/></svg>
          Pannello Amministratore
        </button>

        <!-- Exit button to close the settings menu -->
        <button @click="close" class="flex items-center px-4 py-2 border border-red-500 text-red-500 rounded-md hover:bg-gray-700 transition clickable-shadow">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
          Esci
        </button>
      </div>
    </div>

    <!-- Admin Panel (only visible when managing gallery or logging in) -->
    <div v-if="showAdminPanel" class="bg-white rounded-xl p-6 shadow-lg w-11/12 max-w-lg mx-auto">
      <div v-if="!isAuthenticated">
        <h2 class="mb-4 text-2xl font-bold text-center">Admin Access</h2>
        <input
          v-model="admincode"
          type="text"
          placeholder="Enter admin code"
          class="mb-4 p-3 border border-gray-300 rounded w-full"
        />
        <div class="flex justify-between">
          <button @click="verifyCode" class="px-4 py-2 bg-azzurro-app text-white rounded clickable-shadow">
            Accedi
          </button>
          <button @click="closeAdminPanel" class="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition clickable-shadow">
            Annulla
          </button>
        </div>
        <p v-if="error" class="mt-2 text-red-500 text-center">{{ error }}</p>
      </div>

      <!-- Admin Panel for managing the gallery -->
      <div v-else>
        <h2 class="mb-4 text-2xl font-bold text-center">Modifica Galleria</h2>
        <input
          v-model="newtitle"
          type="text"
          placeholder="Enter title"
          class="mb-4 p-3 border border-gray-300 rounded w-full"
          maxlength="60"
        />
        <div class="text-right text-sm text-gray-500 mb-2">
          {{ newtitle.length }}/60
        </div>
        <textarea
          v-model="newdescription"
          placeholder="Enter description"
          class="mb-4 p-3 border border-gray-300 rounded w-full"
          rows="4"
          maxlength="160"
        ></textarea>
        <div class="text-right text-sm text-gray-500 mb-2">
          {{ newdescription.length }}/160
        </div>
        <div class="flex justify-between">
          <button @click="saveChanges" class="px-4 py-2 bg-azzurro-app text-white rounded transition clickable-shadow">
            Salva
          </button>
          <button @click="closeAdminPanel" class="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition clickable-shadow">
            Annulla
          </button>
          <button @click="logout" class="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition clickable-shadow">
            Esci
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "settingsPopup",
  data() {
    return {
      admincode: "",
      isAuthenticated: false,
      error: "",
      newtitle: this.title,
      newdescription: this.description,
      showAdminPanel: false
    };
  },
  props: {
    title: String,
    description: String,
    galleryid: String
  },
  mounted() {
    this.isAuthenticated = localStorage.getItem(`admincodehashforgalleryid:${this.galleryid}`) ? true : false;
  },
  methods: {
    toggleAdminPanel() {
      this.showAdminPanel = !this.showAdminPanel;
    },
    closeAdminPanel() {
      this.showAdminPanel = false;
    },
    close() {
      this.$emit('close');
    },
    async hashCode(code) {
      const encoder = new TextEncoder();
      const data = encoder.encode(code);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
    },
    async verifyCode() {
      try {
        const hashedAdminCode = await this.hashCode(this.admincode);
        const response = await fetch(`${this.$root.serverurl}/verifyadmincode`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            galleryid: this.galleryid,
            admincode: hashedAdminCode
          })
        });
        const result = await response.json();
        if (result.valid) {
          this.isAuthenticated = true;
          this.error = "";
          localStorage.setItem(`admincodehashforgalleryid:${this.galleryid}`, hashedAdminCode);
        } else {
          this.error = result.message;
        }
      } catch (error) {
        this.error = "An error occurred while verifying the code.";
      }
    },
    async saveChanges() {
      if (this.newtitle.length > 60 || this.newdescription.length > 160) {
        this.error = 'Il titolo o la descrizione hanno superato il limite di caratteri.';
        return;
      }

      try {
        const response = await fetch(`${this.$root.serverurl}/updategallery/${this.galleryid}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: this.newtitle,
            description: this.newdescription,
            admincodehash: localStorage.getItem(`admincodehashforgalleryid:${this.galleryid}`) 
          })
        });
        const result = await response.json();
        if (response.ok) {
          alert('Changes saved successfully!');
          this.$emit('closeandapply');
        } else {
          this.error = result.message;
        }
      } catch (error) {
        this.error = "An error occurred while saving changes.";
      }
    },
    logout() {
      localStorage.removeItem(`admincodehashforgalleryid:${this.galleryid}`);
      this.isAuthenticated = false;
    }
  }
};
</script>

<style scoped>
.placeholder-center::placeholder {
  text-align: center;
}
</style>
