<template>
  <div class="m-8">
    <div class="grid">
      <input
        v-model="email"
        class="text-md  m-4 py-2 flex items-center border-azzurro-app border rounded-lg placeholder-center text-center"
        placeholder="LA TUA EMAIL"
      />
      <!-- Aggiungi contatore per l'email se necessario -->

      <input
        v-model="name"
        class="text-md  mx-4 m-1 py-2 flex items-center border-azzurro-app border rounded-lg placeholder-center text-center"
        placeholder="NOME GALLERY"
        maxlength="60"
      />
      <div class="text-right text-sm text-gray-500 mx-4">
        {{ name.length }}/60
      </div>

      <textarea
        v-model="description"
        class="text-md mx-4 m-1 py-2 flex items-center border-azzurro-app border rounded-lg placeholder-center text-center"
        placeholder="DESCRIZIONE"
        maxlength="160"
      ></textarea>
      <div class="text-right text-sm text-gray-500 mx-4">
        {{ description.length }}/160
      </div>

      <button
        class="bg-azzurro-app text-white font-bold py-2 px-4 rounded-lg m-4 text-xl clickable-shadow"
        @click="createGallery"
      >
        + CREA
      </button>
    </div>
    <div class="flex flex-col items-center mt-4">
      <div class="flex items-center space-x-8">
        <div class="text-left">
          <span class="block text-2xl font-bold text-gray-800">{{ Gallery }}</span>
          <span class="text-sm text-gray-500">Galleries</span>
        </div>
        <div class="text-left">
          <span class="block text-2xl font-bold text-gray-800">{{ Snappies }}</span>
          <span class="text-sm text-gray-500">Snappies</span>
        </div>
        <div class="text-left">
          <span class="block text-2xl font-bold text-gray-800">{{ Likes }}</span>
          <span class="text-sm text-gray-500">Likes</span>
        </div>
        <div class="text-left">
          <span class="block text-2xl font-bold text-gray-800">{{ Smiles }}</span>
          <span class="text-sm text-gray-500">Smiles</span>
        </div>
      </div>
    </div>

<!-- Aggiunta dei bottoni dal file immagine -->
<div class="flex justify-center mt-28 space-x-12">
      <div class="flex flex-col items-center" @click="openInfoPage">
        <div class="w-16 h-16 bg-azzurro-app rounded-full flex items-center justify-center border-2 border-white outline outline-2 outline-azzurro-app clickable-shadow">
          <!-- Info SVG Icon -->
          <svg xmlns="http://www.w3.org/2000/svg" height="44px" viewBox="0 -960 960 960" width="44px" fill="#ffffff"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
        </div>
        <span class="mt-2 text-center text-gray-600">Info</span>
      </div>

      <div class="flex flex-col items-center" @click="sendAssistanceEmail">
        <div class="w-16 h-16 bg-azzurro-app rounded-full flex items-center justify-center border-2 border-white outline outline-2 outline-azzurro-app clickable-shadow">
          <!-- Assistance SVG Icon -->
          <svg xmlns="http://www.w3.org/2000/svg" height="44px" viewBox="0 -960 960 960" width="44px" fill="#ffffff"><path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
        </div>
        <span class="mt-2 text-center text-gray-600">Assistance</span>
      </div>

      <div class="flex flex-col items-center" @click="sendContactEmail">
        <div class="w-16 h-16 bg-azzurro-app rounded-full flex items-center justify-center border-2 border-white outline outline-2 outline-azzurro-app clickable-shadow">
          <!-- Contact Us SVG Icon -->
          <svg xmlns="http://www.w3.org/2000/svg" height="44px" viewBox="0 -960 960 960" width="44px" fill="#ffffff"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v58q0 59-40.5 100.5T740-280q-35 0-66-15t-52-43q-29 29-65.5 43.5T480-280q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480v58q0 26 17 44t43 18q26 0 43-18t17-44v-58q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h200v80H480Zm0-280q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z"/></svg>
        </div>
        <span class="mt-2 text-center text-gray-600">Contact us</span>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      name: '',
      email: '',
      description: '',
      Gallery: '',
      Likes: '',
      Snappies: '',
      Smiles: '',
      errorMessage: ''
    };
  },
  computed: {
    isButtonDisabled() {
      return (
        this.name.length === 0 ||
        this.description.length === 0 ||
        this.email.length === 0 ||
        this.name.length > 60 ||
        this.description.length > 160
      );
    }
  },
  methods: {
    validateFields() {
      if (this.name.length === 0) {
        this.errorMessage = 'Il nome della galleria è obbligatorio.';
        return false;
      }
      if (this.description.length === 0) {
        this.errorMessage = 'La descrizione è obbligatoria.';
        return false;
      }
      if (this.email.length === 0) {
        this.errorMessage = "L'email è obbligatoria.";
        return false;
      }
      if (this.name.length > 60) {
        this.errorMessage = 'Il nome della galleria non può superare i 60 caratteri.';
        return false;
      }
      if (this.description.length > 160) {
        this.errorMessage = 'La descrizione non può superare i 160 caratteri.';
        return false;
      }
      this.errorMessage = '';
      return true;
    },
    validateEmail(email) {
      // Simple email validation regex
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    async createGallery() {
      if (!this.validateEmail(this.email)) {
        alert("L'email inserita non è valida.");
        return;
      }
      
      if (!this.validateFields()) {
        alert(this.errorMessage);
        return; // Non inviare la richiesta se la validazione fallisce
      }

      try {
        const response = await fetch(`${this.$root.serverurl}/newgallery`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: this.name,
            description: this.description,
            creator_email: this.email
          })
        });
        const result = await response.json();
        if (response.ok) {
          this.$router.push({ name: 'viewgallery', params: { id: result.gallery_id } });
          this.fetchStats(); // Refresh statistics after creating a gallery
        } else {
          console.error(result.error);
          this.errorMessage = result.error || 'Errore nella creazione della galleria.';
        }
      } catch (error) {
        console.error('Errore nella creazione della galleria:', error);
        this.errorMessage = 'Errore nella creazione della galleria.';
      }
    },
    async fetchStats() {
      try {
        const response = await fetch(`${this.$root.serverurl}/stats`);
        const stats = await response.json();
        if (response.ok) {
          this.Gallery = stats.galleries;
          this.Snappies = stats.images;
          this.Likes = stats.likes;
          this.Smiles = stats.smiles;
        } else {
          console.error(stats.error);
        }
      } catch (error) {
        console.error('Errore nel recupero delle statistiche:', error);
      }
    },
    sendContactEmail() {
      window.location.href = `mailto:info@oursnappy.com?subject=Contact%20Us`;
    },
    sendAssistanceEmail() {
      window.location.href = `mailto:support@oursnappy.com?subject=Assistance%20Request`;
    },
    openInfoPage() {
      window.open('https://www.oursnappy.com', '_blank');
    },
  },
  created() {
    this.fetchStats(); // Fetch statistics when the component is created
  }
};
</script>

<style scoped>
.placeholder-center::placeholder {
  text-align: center;
}
.bg-blue-200 {
  background-color: #a5d8ff;
}
</style>
