<template>
  <div class="bottom-bar fixed bottom-0 bg-white w-full py-2 px-2">
    <div class="grid grid-cols-3">
      <button 
        @click="selectTab('openinfomenu')" 
        :class="{ 'text-white': menuselected === 'openinfomenu' }" 
        class="mr-4 flex items-center justify-center"
      >
        <div class="rounded-full bg-azzurro-app p-2 clickable-shadow border-2 border-white outline outline-1 outline-azzurro-app">
          <svg class="" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#ffffff">
            <path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z"/>          </svg>
        </div>
      </button>
        <button @click="selectTab('uploadphoto')" :class="{ 'text-white': menuselected === 'uploadphoto' }" class="mr-4 flex items-center justify-center">
          <div class="rounded-full bg-azzurro-app p-3 clickable-shadow border-2 border-white outline outline-1 outline-azzurro-app">
            <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#ffffff"><path d="M440-440ZM120-120q-33 0-56.5-23.5T40-200v-480q0-33 23.5-56.5T120-760h126l74-80h240v80H355l-73 80H120v480h640v-360h80v360q0 33-23.5 56.5T760-120H120Zm640-560v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80ZM440-260q75 0 127.5-52.5T620-440q0-75-52.5-127.5T440-620q-75 0-127.5 52.5T260-440q0 75 52.5 127.5T440-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Z"/></svg>
          </div>
        </button>
        <button @click="selectTab('select')" :class="{ 'text-white': menuselected === 'print' }" class="flex items-center justify-center">
          <div class="rounded-full bg-azzurro-app p-2 clickable-shadow border-2 border-white outline outline-1 outline-azzurro-app">
            <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#ffffff"><path d="M593-80q-24 0-46-9t-39-26L304-320l33-34q14-14 34-19t40 0l69 20v-287q0-17 11.5-28.5T520-680q17 0 28.5 11.5T560-640v393l-98-28 103 103q6 6 13 9t15 3h167q33 0 56.5-23.5T840-240v-160q0-17 11.5-28.5T880-440q17 0 28.5 11.5T920-400v160q0 66-47 113T760-80H593Zm7-280v-160q0-17 11.5-28.5T640-560q17 0 28.5 11.5T680-520v160h-80Zm120 0v-120q0-17 11.5-28.5T760-520q17 0 28.5 11.5T800-480v120h-80Zm40 200H565h195Zm-600-40q-33 0-56.5-23.5T80-280v-480q0-33 23.5-56.5T160-840h600q33 0 56.5 23.5T840-760v160h-80v-160H160v480h72l79 80H160Z"/></svg>
          </div>
          </button>
        </div>
    </div>
</template>
  
<script>

// da integrare i bottoni con il resto
export default {
  data() {
    return {
      menuselected: 'home'
    };
  },
  methods: {
    selectTab(menuselected) {
      this.$emit("menuselected",menuselected)  // componente madre ha il tab, accedo dalle altre pagine direttamente su quella variabile
    }
  }
};
</script>