<template>
<header class="fixed top-0 left-0 right-0 bg-white z-10">
  <img src="./assets/logo.png" class="w-32 mt-4 mb-2 mx-auto"/>
</header>
<div >
  <div class="mt-24"></div>
  <homePage v-if="this.$route.name == 'viewgallery'" ref="home" />
  <router-view v-else  />

  <bottomBar v-if="this.$route.name == 'viewgallery'"  @menuselected="changemenuselected"/>
</div>
</template>

<script>
import homePage from './components/home.vue'
import bottomBar from './components/bottomBar.vue';

export default {
  name: 'App',
  components: {
    homePage,
    bottomBar,
  },
  data() {
    return {
      galleryID: this.$route.params.id,
      serverurl: 'https://www.oursnappy.com/api' //'https://www.oursnappy.com/api' //'http://localhost:8888/api',
    };
  },
  created() {
    this.incrementVisitCount();
  },
  methods:{
    changemenuselected(menuselected){
      if (menuselected === 'newgallery') {
        this.$router.push({ name: 'newgallery' });
        return;
      }      
      this.$refs.home.changemenuselected(menuselected);
    },
    async incrementVisitCount() {
      try {
        await fetch(`${this.serverurl}/incrementvisit`, {
          method: 'POST',
        });
      } catch (error) {
        console.error('Error incrementing visit count:', error);
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
