<template>
  <div class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-full bg-black bg-opacity-75">
    <div class="w-5/6 bg-white rounded-xl shadow-md">
      <div>
        <div class="text-center items-center">
          <div class="inline-block" ref="qrContainer">
          </div>
        </div>
        <div class="flex justify-center items-center">
          <button v-show="webShareApiSupported()" class="bg-azzurro-app text-white font-bold py-2 px-4 rounded m-4 text-md clickable-shadow" @click="shareViaWebShare">
            CONDIVIDI
          </button>
          <div>
            <button v-show="!webShareApiSupported() && !linkCopiato" class="bg-azzurro-app text-white font-bold py-2 px-4 rounded m-4 text-md clickable-shadow" @click="copylink">
              COPIA LINK
            </button>
            <button v-show="!webShareApiSupported() && linkCopiato" class="bg-lime-500 text-white font-bold py-2 px-4 rounded m-4 text-md clickable-shadow" @click="copylink">
              LINK COPIATO
            </button>
          </div>
          <button v-show="qrCodeVisible" class="bg-azzurro-app text-white font-bold py-2 px-4 rounded m-4 text-md clickable-shadow" @click="saveQrcodeImage">
            SALVA QR CODE
          </button>
        </div>
        <div class="flex justify-center"> <!-- Aggiunto il div con la classe flex justify-center -->
          <button class="bg-red-500 text-white font-bold py-2 px-4 rounded m-4 text-md clickable-shadow" @click="closepopup">
            ANNULLA
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  

<script>
import QRCode from 'qrcode';

export default {
  data() {
    return {
      url: window.location.href,
      qrCodeVisible: false,
      qrCanvas: null, // Aggiungiamo una variabile per memorizzare il canvas del codice QR
      linkCopiato:false,
    };
  },
  mounted(){
    this.createPic()
  },
  methods: {
    closepopup(){
      this.$emit('close')
    },
    async createPic() {
      try {
        if (!this.url) return;
        const canvas = await QRCode.toCanvas(this.url, { width: 256, height: 256 }); // Imposta le dimensioni desiderate
        
        // Rimuovi il canvas precedente se presente
        if (this.qrCanvas) {
          this.qrCanvas.remove();
        }
        
        // Aggiungi il nuovo canvas al DOM
        this.$refs.qrContainer.appendChild(canvas);
        this.qrCanvas = canvas;
        
        this.qrCodeVisible = true;
      } catch (error) {
        console.error('Errore nella generazione del codice QR:', error);
      }
    },
      shareViaWebShare() {
          navigator.share({
              url: `${this.url}`//https://ourpics.it/gallery?id=${this.url}
          })
      },
      webShareApiSupported() {
          return navigator.share
      },
      saveQrcodeImage() {
          if (!this.qrCanvas) return;

          // Ottieni i dati dell'immagine in formato base64 dal canvas
          const imageData = this.qrCanvas.toDataURL('image/png');

          // Crea un elemento link per il download dell'immagine
          const link = document.createElement('a');
          link.href = imageData;
          link.download = 'qr_code.png';
          link.click();
        },
        copylink(){
            let t = this
        // Copia l'URL corrente negli appunti
        navigator.clipboard.writeText(window.location.href)
            .then(function() {
            t.linkCopiato = true;
            setTimeout(function() {
                t.linkCopiato = false;
            }, 5000);
            })
            .catch(function(error) {
            console.error('Errore durante la copia dell\'URL:', error);
            });
        }
    }
};
</script>
