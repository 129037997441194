<template>
    <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
      <div class="bg-white rounded-xl p-6 shadow-lg w-11/12 max-w-lg mx-auto">
        <h2 class=" text-2xl font-bold text-center">Inserisci Username</h2>
        <p v-if="!isuseramenull()" class="text-gray-800 text-xs text-center mb-4">Nota: Il nuovo username verrà applicato solo alle immagini che caricherai successivamente.</p>
        <input
          v-model="username"
          type="text"
          placeholder="Inserisci il tuo username"
          class="mb-4 p-3 border border-gray-300 rounded w-full"
          maxlength="20"
        />
        <div class="text-right text-sm text-gray-500 mb-2">
          {{ username.length }}/20
        </div>
        <div class="flex justify-between">
          <button @click="saveUsername" class="px-4 py-2 bg-azzurro-app text-white rounded clickable-shadow">
            Salva
          </button>
          <button v-if="!isuseramenull()" @click="close" class="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition clickable-shadow">
            Annulla
          </button>
        </div>
        <p v-if="error" class="mt-2 text-red-500 text-center">{{ error }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "usernamePopup",
    data() {
      return {
        username: localStorage.getItem('username') || '',
        error: '',
      };
    },
    methods: {
      saveUsername() {
        if (this.username.length > 20) {
          this.error = 'Il nome utente non può superare i 20 caratteri.';
          return;
        }
        if (this.username.length <= 0) {
          this.error = 'Il nome utente non può essere vuoto.';
          return;
        }
        
        localStorage.setItem('username', this.username);
        alert('Username salvato con successo!');
        this.$emit('close');
      },
      close() {
        this.$emit('close');
      },
      isuseramenull(){
        return localStorage.getItem('username') === null
      }
    }
  };
  </script>
  
  <style scoped>
  .placeholder-center::placeholder {
    text-align: center;
  }
  </style>
  