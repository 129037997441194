<template>
    <div class="fixed top-0 left-0 flex items-center justify-center w-screen h-full bg-black bg-opacity-75">
      <div class="w-11/12 sm:w-5/6 md:w-3/4 lg:w-1/2 bg-white rounded-xl shadow-lg p-6">
        <h2 class="text-3xl font-bold mb-6 text-center">Estendi Galleria</h2>
        <form @submit.prevent="extendGallery" class="space-y-6">
          <div class="flex flex-col space-y-2">
            <label for="extension_days" class="block text-sm font-medium text-gray-700">Giorni di Estensione:</label>
            <input 
              type="number" 
              v-model="extensionDays" 
              required 
              @input="calculateNewExpiryDate"
              class="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-base"
            />
          </div>
          <div class="flex flex-col space-y-2">
            <p class="text-md font-medium text-gray-700">Data di Scadenza Attuale: <span class="text-azzurro-app font-bold">{{ formatDate(currentExpiryDate) }}</span></p>
            <p class="text-md font-medium text-gray-700">Prossima Data di Scadenza: <span class="text-azzurro-app font-bold">{{ formatDate(newExpiryDate) }}</span></p>
            <p class="text-md font-medium text-gray-700">Importo: <span class="text-azzurro-app font-bold">{{ amount }} €</span></p>
          </div>
          <div class="flex justify-centre space-x-4">
            <button 
              type="submit" 
              class=" clickable-shadow inline-flex justify-center w-full py-2 px-6 border border-transparent shadow-sm text-base rounded-md text-white bg-azzurro-app focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 font-bold"
            >
              Estendi
            </button>
            <button 
              type="button" 
              @click="closePopup" 
              class="clickable-shadow inline-flex justify-center w-full py-2 px-6 border border-transparent shadow-sm text-base rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 font-bold"
            >
              Annulla
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      galleryId: {
        type: String,
        required: true
      },
      currentExpiryDate: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        amount: 'FREE',
        extensionDays: '',
        newExpiryDate: this.currentExpiryDate,
        message: ''
      };
    },
    methods: {
      async extendGallery() {
        try {
          const response = await fetch(`${this.$root.serverurl}/galleries/${this.galleryId}/extend`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              amount: 10,
              extension_days: this.extensionDays
            })
          });
          const data = await response.json();
          alert(data.message);
          this.$emit('closeandapply');
        } catch (error) {
          alert('Si è verificato un errore');
          this.message = 'Si è verificato un errore';
        }
      },
      closePopup() {
        this.$emit('close');
      },
      calculateNewExpiryDate() {
        if (this.extensionDays) {
          const currentDate = new Date(this.currentExpiryDate);
          const newDate = new Date(currentDate);
          newDate.setDate(newDate.getDate() + parseInt(this.extensionDays));
          this.newExpiryDate = newDate.toISOString().split('T')[0];
        } else {
          this.newExpiryDate = this.currentExpiryDate;
        }
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('it-IT', options);
      }
    }
  };
  </script>
  
  <style>
  /* Custom styles for the popup component */
  </style>
  