import { createApp } from 'vue'
import App from './App.vue'
import './style.css'
import { createRouter, createWebHistory } from 'vue-router'

// Importa i componenti delle tue pagine
import homePage from './components/home.vue'
import newGallery from './components/newGallery.vue'
import galleryActivation from './components/galleryActivation.vue'

const routes = [
  { name: 'newgallery' , path: '/app/', component: newGallery },
  { name: 'Gallery Activation' , path: '/app/gallery/activate/:activationid', component: galleryActivation },
  { name: 'viewgallery' , path: '/app/gallery/:id', component: homePage }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

createApp(App).use(router).mount('#app')
