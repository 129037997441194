<template>
  <div class="fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-full bg-black bg-opacity-75">
      <div class="w-5/6 bg-white rounded-xl shadow-md">
          <div class="flex items-center justify-center">
              <div class="flex items-center m-4 w-full">
                  <div class="h-full w-full border-2 border-dashed rounded-lg border-gray-400 flex justify-center items-center pb-2" @dragover.prevent @drop="onDrop">
                      <div>
                          <label class="block my-2 text-ml font-bold text-gray-700" for="file_input"></label>
                          <button @click="triggerFileInput" class="m-2 block text-sm text-gray-600 cursor-pointer focus:outline-none pt-1">
                              <svg class="w-20 h-20" fill="#FFFFFF" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                              </svg>
                          </button>
                          <input @change="onSelectImage" @dragover.prevent @drop="onSelectImage" accept=".png,.jpg,.jpeg" class="m-2 block text-sm text-gray-900 cursor-pointer focus:outline-none hidden" id="file_input" type="file" multiple>
                      </div>
                      <div class="flex space-x-4 mt-4 max-w-screen-xl overflow-x-auto">
                          <div v-for="image in filesPreviewUrl" :key="image" class="h-20 w-20 relative flex-shrink-0">
                              <button @click="deleteImage(image)" type="button" class=" clickable-shadow absolute top-0 right-0 bg-white h-6 w-6 rounded-full mt-1 mr-1 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                  <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                  </svg>
                              </button>
                              <img :src="image" alt="Image" class="rounded-lg object-cover h-20 w-20 object-center object-contain">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- Bottoni -->
          <div class="flex items-center justify-center mb-2">
              <button v-if="this.files.length > 0" class="rounded text-white p-2 clickable-shadow mr-4" :class="{'bg-azzurro-app':this.files.length > 0, 'bg-gray-400':this.files.length == 0}" @click="uploadImages" :disabled="isLoading">
                  <span v-if="!isLoading">CARICA</span>
                  <div v-if="isLoading" class="flex">
                      <span class="mr-2">{{ image_upload_percentage }}%</span>
                      <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                  </svg>
                  </div>
              </button>
              <button class="rounded bg-red-500 text-white p-2  clickable-shadow" @click="closephoto">ANNULLA</button>
          </div>
          <div v-if="errorMessage" class="text-red-500 mt-4">
              {{ errorMessage }}
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default {
name: "uploadPhoto",
data() {
  return {
      files: [],
      filesPreviewUrl: [],
      errorMessage: '',
      isLoading: false,
      client_id_upload: null,
      image_upload_percentage: 0,
  };
},
mounted() {
  this.client_id_upload = localStorage.getItem('client_id_upload') || uuidv4();
  localStorage.setItem('client_id_upload', this.client_id_upload);
},
methods: {
  closephoto() {
    this.$emit('close')
  },
  onDrop(event) {
    event.preventDefault();
    let validFiles = [];
    for (let file of event.dataTransfer.files) {
      if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
        validFiles.push(file);
      } else {
        alert('File non supportato');
      }
    }
    this.files = this.files.concat(validFiles);
    this.previewFiles();
  },
  onSelectImage(event) {
    let validFiles = [];
    for (let file of event.target.files) {
      if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
        validFiles.push(file);
      } else {
        alert('File non supportato');
      }
    }
    this.files = this.files.concat(validFiles);
    this.previewFiles();
  },
  previewFiles() {
    this.filesPreviewUrl = [];
    for (let index in this.files) {
      this.showImage(this.files[index], index);
    }
  },
  showImage(file, index) {
    const reader = new FileReader();
    reader.onload = () => {
      this.files[index].image_url = reader.result;
      this.filesPreviewUrl.push(reader.result);
    };
    reader.readAsDataURL(file);
  },
  deleteImage(image) {
    const index = this.filesPreviewUrl.indexOf(image);
    if (index !== -1) {
      this.files.splice(index, 1);
      this.filesPreviewUrl.splice(index, 1);
    }
  },
  triggerFileInput() {
    document.getElementById('file_input').click();
  },
  async uploadImages() {
    if (this.files.length === 0) {
      return;
    }
    this.isLoading = true;
    this.errorMessage = '';
    let uploadedCount = 0;

    try {
      // Divide le immagini in blocchi di 5
      const totalImages = this.files.length;
      for (let i = 0; i < totalImages; i += 5) {
        const batch = this.files.slice(i, i + 5);
        const formData = new FormData();
        formData.append('gallery_id', this.$route.params.id); 
        formData.append('client_id_upload', this.client_id_upload);
        if (localStorage.getItem('username') != null) {
          formData.append('upload_username', localStorage.getItem('username'));
        }

        batch.forEach(file => {
          formData.append('images[]', file);
        });

        // Invio del batch
        const response = await axios.post(this.$root.serverurl + '/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        // Aggiorna il conteggio e la percentuale
        uploadedCount += batch.length;
        const progressPercentage = Math.round((uploadedCount / totalImages) * 100);
        this.image_upload_percentage = Math.round(progressPercentage * 100) / 100;
      }

      alert('Immagini caricate con successo');
      this.$emit('closeandapply');
    } catch (error) {
      this.errorMessage = error.response ? error.response.data.error : 'Errore durante il caricamento delle immagini';
    } finally {
      this.isLoading = false;
    }
  },
}
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
</style>
